import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import './App.css';

const Form37 = React.lazy(() => import('./Forms/form37'));
const Form38 = React.lazy(() => import('./Forms/form38'));

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="37" element={<Form37 />} />
                <Route path="laredoute_439_39_69/38/:guid?" element={<Form38 />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
